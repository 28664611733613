if (typeof window !== `undefined`) {

    const template = document.createElement('template');
    template.innerHTML = `
 <style>
    :host {
        display: flex;
        align-items: center;
        justify-content: center;
        appearance: none;
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
    }

    .hamburger {
        display: grid;
        grid-template-rows: var(--menu-toggle-bar-height, 0.1875rem);
        grid-template-columns: var(--menu-toggle-bar-width, 1.5rem);
        grid-template-areas: 'bar';
        align-content: center;
        justify-content: center;
        width: var(--menu-toggle-width, 3.5em);
        height: var(--menu-toggle-width, 3.5em);
    }

    .hamburger span {
        grid-area: bar;
        background-color: var(--menu-toggle-bar-color, var(--holy-black));
        border-radius: 2px;
        transition: background-color 150ms ease-in-out,
            transform 150ms ease-in-out;
    }

    .hamburger span:first-child {
        transform: translate3d(0, -0.4375rem, 0);
    }

    .hamburger span:last-child {
        transform: translate3d(0, 0.4375rem, 0);
    }

    :host([active]) .hamburger span {
        background-color: var(
            --menu-toggle-bar-active-color,
            var(--holy-black)
        );
    }

    :host(:hover) .hamburger span:first-child {
        transform: translate3d(0.25rem, -0.4375rem, 0);
    }

    :host(:hover) .hamburger span:nth-child(2) {
        transform: translate3d(-0.25rem, 0, 0);
    }

    :host(:hover) .hamburger span:last-child {
        transform: translate3d(0.25rem, 0.4375rem, 0);
    }

    :host([active]) .hamburger span:first-child {
        transform: var(--menu-toggle-bar-1st-active-transform);
    }

    :host([active]) .hamburger span:nth-child(2) {
        transform: var(--menu-toggle-bar-2nd-active-transform);
    }

    :host([active]) .hamburger span:last-child {
        transform: var(--menu-toggle-bar-3rd-active-transform);
    }

    .title {
        display: none;
        font-family: var(--menu-toggle-title-font-family);
        font-weight: 700;
        color: var(--menu-toggle-title-color);
        transition: color 150ms ease-in-out;
    }

    :host([active]) .title {
        color: var(--menu-toggle-title-active-color);
    }

    @media screen and (min-width: 32em) {
        .title {
            display: block;
        }

        .title:empty {
            display: none;
        }
    }
</style>

<span class="title"></span>

<div class="hamburger">
    <span></span>
    <span></span>
    <span></span>
</div>
`;

    /**
     * Menu toggle element to add and remove a class to the body.
     * @extends    HTMLElement
     */
    class MenuToggleElement extends HTMLElement {
        static get defaultActiveClass() {
            return 'menu-is-open';
        }

        /**
         * Fire the attributeChangedCallback on these attributes.
         */
        static get observedAttributes() {
            return ['title', 'active'];
        }

        constructor() {
            super();
            const shadow = this.attachShadow({mode: 'open'});
            shadow.append(template.content.cloneNode(true));
        }

        /**
         * Gets and set the active attribute.
         * @property
         */
        get active() {
            return this.hasAttribute('active');
        }

        set active(value) {
            if (value === true) {
                this.setAttribute('active', '');
            } else {
                this.removeAttribute('active');
            }
        }

        /**
         * Gets and set the active class.
         * @property
         */
        get activeClass() {
            return this.getAttribute('active-class');
        }

        set activeClass(value) {
            if ('string' === typeof value) {
                this.setAttribute('active-class', value);
            }
        }

        /**
         * Gets and sets the title attribute.
         * @property
         */
        get title() {
            return this.getAttribute('title');
        }

        set title(value) {
            if ('string' === typeof value) {
                this.setAttribute('title');
            }
        }

        /**
         * Act whenever an attribute has been modified.
         * @param {string} attrname The name of the changed attribute.
         */
        attributeChangedCallback(attrname) {
            switch (attrname) {
                case 'title': {
                    const titleElement = this.shadowRoot.querySelector('.title');
                    titleElement.textContent = this.title;
                    break;
                }
                case 'active':
                    document.body.classList.toggle(this.activeClass, this.active);
                    break;
            }
        }

        /**
         * Set the default class if activeClass is not set.
         * Add the event listeners on connect.
         */
        connectedCallback() {
            if (this.activeClass === null) {
                this.activeClass = MenuToggleElement.defaultActiveClass;
            }

            if (document.body.classList.contains(this.activeClass)) {
                this.active = true;
            }

            this.addEventListener('click', this.#onClick);
        }

        /**
         * Remove the event listener on disconnect.
         */
        disconnectedCallback() {
            this.removeEventListener('click', this.#onClick);
        }

        /**
         * Click event handler.
         */
        #onClick = () => {
            this.active = !this.active;
        };
    }

    window.customElements.define('menu-toggle', MenuToggleElement);
}
