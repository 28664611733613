import React from 'react';
import Logo from '../../content/images/logo.svg';
import {Link} from 'gatsby';
import '../web-components/dynamic-banner';
import MobileMenu from "./mobile-menu";
import Menu from "./menu";

export const Header = () => (
      <>
          <dynamic-banner threshold="600" className="banner">
              <div className="container col-xl-12 col-xxl-8">
                  <header className="header d-flex flex-wrap justify-content-between py-2">
                      <Link to="/" className="site-logo d-flex align-items-center me-md-auto text-dark text-decoration-none" title="Home">
                          <Logo height="60" width="60" className="bi me-2" aria-label="Website logo"/>
                          <h1 className="ps-2 text-horizon fs-4 mb-0">Holy Crêpe</h1>
                      </Link>
                      <Menu/>
                      <MobileMenu/>
                  </header>
              </div>
          </dynamic-banner>
      </>
);
