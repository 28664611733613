import React from 'react';
import {useEventTriggered} from '../helpers/hooks/serviceWorkerHooks';
import {useIntl} from "gatsby-plugin-react-intl";

function reload() {
  window.location.reload();
}

export const UpdateBar = () => {
  const [isUpdate] = useEventTriggered('onServiceWorkerUpdate');

  const intl = useIntl().formatMessage;

  return (
    <>
        {isUpdate &&
            <div className="update-bar">
              {intl({ id: 'update.title' })}
                <button
                  className="update-bar-action"
                  onClick={reload}>
                    {intl({ id: 'update.buttonText' })}
                </button>
            </div>
        }
    </>
  );
}
