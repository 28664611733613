import React from 'react';
import '../styles/main.scss';
import {Header} from './header';
import {Footer} from './footer';
import {UpdateBar} from './update-bar';
import PropTypes from 'prop-types';

export const Layout = ({children, simple, fullwidth, bodyClass}) => {

    return (
        <>
          <div className={bodyClass}>
            {!simple && <Header/>}
            {!fullwidth && <div className="container">{children}</div>}
            {fullwidth && children}
            {!simple && <Footer/>}
            <UpdateBar/>
          </div>
        </>
    )
};

Layout.propTypes = {
  simple: PropTypes.bool,
  fullwidth: PropTypes.bool,
  children: PropTypes.node,
  bodyClass: PropTypes.string,
};
