if (typeof window !== `undefined`) {
    /**
     * Banner element that responds on the scrolling of the document.
     * @extends    HTMLElement
     */
    class DynamicBannerElement extends HTMLElement {
        /**
         * Attributes to trigger the attributeChangedCallback on.
         * @returns    {string[]}
         */
        static get observedAttributes() {
            return ['threshold'];
        }

        constructor() {
            super();

            this.lastScrollTop =
                document.scrollingElement.scrollTop ||
                document.documentElement.scrollTop;
        }

        /**
         * Gets and sets the down attribute.
         * @property
         */
        get down() {
            return this.getAttribute('down');
        }

        set down(value) {
            if (value === true) {
                this.setAttribute('down', '');
            } else {
                this.removeAttribute('down');
            }
        }

        /**
         * Gets and sets the room attribute.
         * @property
         */
        get room() {
            return this.getAttribute('room');
        }

        set room(value) {
            if (value === true) {
                this.setAttribute('room', '');
            } else {
                this.removeAttribute('room');
            }
        }

        /**
         * Gets and sets the scrolled attribute.
         * @property
         */
        get scrolled() {
            return this.getAttribute('scrolled');
        }

        set scrolled(value) {
            if (value === true) {
                this.setAttribute('scrolled', '');
            } else {
                this.removeAttribute('scrolled');
            }
        }

        /**
         * Gets and sets the sticky attribute.
         * @property
         */
        get sticky() {
            return this.getAttribute('sticky');
        }

        set sticky(value) {
            if (value === true) {
                this.setAttribute('sticky', '');
            } else {
                this.removeAttribute('sticky');
            }
        }

        /**
         * Gets and sets the threshold attribute.
         * @property
         */
        get threshold() {
            return parseInt(this.getAttribute('threshold'));
        }

        set threshold(value) {
            const number = Number(value);
            if (!Number.isNaN(number)) {
                this.setAttribute('threshold', number);
            } else {
                this.removeAttribute('threshold');
            }
        }

        /**
         * Gets and sets the up attribute.
         * @property
         */
        get up() {
            return this.getAttribute('up');
        }

        set up(value) {
            if (value === true) {
                this.setAttribute('up', '');
            } else {
                this.removeAttribute('up');
            }
        }

        /**
         * Fires when an attribute has been changed.
         *
         * @method    attributeChangedCallback
         * @param    {String} attrName Name of attribute.
         * @param    {*} oldValue Old value of attribute.
         * @param    {*} newValue New value of attribute.
         */
        attributeChangedCallback(attrName) {
            switch (attrName) {
                case 'threshold':
                    this.onScroll();
                    break;
            }
        }

        /**
         * Fires when the element has been connected.
         *
         * @method    connectedCallback
         * @returns    {void}
         */
        connectedCallback() {
            // Set default threshold if it is not set.
            if (isNaN(this.threshold)) {
                this.threshold = this.offsetHeight + this.offsetTop;
            }

            window.addEventListener('scroll', this.onScroll, {passive: true});
        }

        /**
         * Fires when the element has been disconnected.
         *
         * @method    disconnectedCallback
         * @returns    {void}
         */
        disconnectedCallback() {
            window.removeEventListener('scroll', this.onScroll, {passive: true});
        }

        /**
         * Scroll event handler.
         * @return {void}
         */
        onScroll = () => {
            requestAnimationFrame(() => {
                const top =
                    document.scrollingElement.scrollTop ||
                    document.documentElement.scrollTop;
                if (top >= this.threshold) {
                    if (this.room !== null) {
                        if (top > this.lastScrollTop) {
                            if (this.up !== null) {
                                this.up = false;
                            }
                            this.down = true;
                        } else if (top < this.lastScrollTop) {
                            if (this.down !== null) {
                                this.down = false;
                            }
                            this.up = true;
                        }
                    }
                    if (this.scrolled === null) {
                        this.scrolled = true;
                    }
                } else {
                    if (this.room !== null) {
                        if (this.up !== null) {
                            this.up = false;
                        }
                        if (this.down !== null) {
                            this.down = false;
                        }
                    }
                    if (this.scrolled !== null) {
                        this.scrolled = false;
                    }
                }
                this.lastScrollTop = top;
            });
        };
    }

    window.customElements.define('dynamic-banner', DynamicBannerElement);
}