/* eslint react/prop-types: 0 */
import React, {useRef} from 'react';
import {links, baseURL} from '../helpers/hooks/use-links';
import '../web-components/menu-toggle';
import { Link } from "gatsby"
import {
    injectIntl
} from 'gatsby-plugin-react-intl'
import Language from "./language";

const MobileMenu = ({ intl }) => {
    const menuRef = useRef(null);

    const handleClick = () => {
        menuRef.current.removeAttribute("active");
    }

    return (
        <div className="d-flex d-lg-none">
            <menu-toggle ref={menuRef} title="Menu"></menu-toggle>
            <div className="navigation">
                <div className="navigation__wrapper">
                    <div className="navigation__container">
                        <ul className="ps-0 list-unstyled text-center">
                            {links.map(({ anchor, label, url }, i) => (
                                <li key={i}>
                                    <Link
                                        onClick={handleClick}
                                        to={`${baseURL[intl.formatMessage({ id: 'lang' })]}${
                                            anchor ? '#' : ''
                                        }${url && intl.formatMessage({ id: url })}`}
                                        alt={intl.formatMessage({ id: label })}
                                        className="d-block fs-2 text-black text-decoration-none py-3"
                                    >
                                        {intl.formatMessage({ id: label })}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <Language classnames={"language-select d-flex align-items-center justify-content-center"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(MobileMenu)
