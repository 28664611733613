/* eslint react/prop-types: 0 */
import React from 'react';
import {links, baseURL} from '../helpers/hooks/use-links';
import '../web-components/menu-toggle';
import { Link } from "gatsby"
import {
    injectIntl
} from 'gatsby-plugin-react-intl'
import Language from "./language";

const Menu = ({ intl }) => {

    return (
        <div className="d-none d-lg-flex">
            <ul className="nav nav-pills nav-items text-center">
                {links.map(({ anchor, label, url }, i) => (
                    <li key={i} className="d-flex align-items-center">
                        <Link
                            to={`${baseURL[intl.formatMessage({ id: 'lang' })]}${
                                anchor ? '#' : ''
                            }${url && intl.formatMessage({ id: url })}`}
                            alt={intl.formatMessage({ id: label })}
                            className="d-block fs-5 px-3 text-black text-decoration-none"
                        >
                            {intl.formatMessage({ id: label })}
                        </Link>
                    </li>
                ))}
            </ul>
            <Language classnames={"language-select d-flex align-items-center"}/>
        </div>
    );
};

export default injectIntl(Menu)
