/* eslint react/prop-types: 0 */
import React from 'react'
import {
    injectIntl,
    IntlContextConsumer,
    changeLocale
} from 'gatsby-plugin-react-intl';
import Select, { components } from 'react-select'

const { Option } = components;
const CustomSelectOption = props => (
    <Option {...props}>
        <span>{props.data.icon} </span>{props.data.label}
    </Option>
)

const colourStyles = {
    control: styles => ({...styles, cursor: 'pointer', minWidth: '80px', fontSize: "1.8em", border: 'none', paddingRight: "5px"}),
    container: styles => ({...styles, display: 'flex', alignItems: 'center'}),
    indicatorSeparator: () => ({display: 'none'}),
    valueContainer: () => ({display: "flex", alignItems: "center", paddingLeft: "0.5em"}),
    dropdownIndicator: () => ({color: "#5c8d9c"}),
    placeholder: () => ({height: '35px', color: "white"}),
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        right: "0"
    }),
    option: (styles, { isDisabled }) => {
        return {
            ...styles,
            color: '#000',
            textAlign: 'left',
            minWidth: "150px",
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    }
};

const CustomSelectValue = props => (<span>{props.data.icon}</span>);
const handleLangChange = (e) => { if ( e ) changeLocale(e); };

const options = [
        { value: 'en', label: 'English', icon: "🇺🇸" },
        { value: 'nl', label: 'Nederlands', icon: "🇳🇱" }
    ];


const Language = ({props}) => (
    <>
        <IntlContextConsumer>
            {({ language: currentLocale }) => {

                return (
                    <Select
                        components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
                        isSearchable={false}
                        defaultValue={options.find(o => o.value === currentLocale).icon}
                        onBlur={(e) => handleLangChange(e.value)}
                        onChange={(e) => handleLangChange(e.value)}
                        options={options}
                        styles={colourStyles}
                        placeholder={options.find(o => o.value === currentLocale).icon}
                        {...props}
                    />
                )}
            }
        </IntlContextConsumer>
    </>
);

export default injectIntl(Language)
