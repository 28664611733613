import React from 'react';
import Logo from "../../content/images/logo.svg";
import {graphql, useStaticQuery} from 'gatsby';
import {SocialLinks} from './social-links';
import {ContentLinks} from "./content-links";

const footerLinksQuery = graphql`
  query {
    site {
      siteMetadata {
        author
        authorUrl
        socialNetworks {
          instagram
          facebook
        }
        copyrightLinks {
          name
          to
          outbound
        }
      }
    }
  }
`;


export const Footer = () => {
    const {site: {siteMetadata: {authorUrl = '', author = '', socialNetworks = {}, copyrightLinks = {}} = {}} = {}} = useStaticQuery(footerLinksQuery);

  return (
      <div className="container">
          <footer className="d-flex flex-wrap flex-column flex-lg-row justify-content-between align-items-center py-3 my-4">
              <div className="col d-flex align-items-center">
                  <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                      <Logo height="50" width="50" className="bi me-2" aria-label="Website logo"/>
                  </a>
                  <div>
                      <span className="text-muted">© Holy Crêpe Centraal <br/>KVK 65383753</span>
                  </div>
              </div>
              <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="mb-1">
                    <ContentLinks links={copyrightLinks}/>
                  </div>
                  <div className="mb-1">
                      Webdevelopment & Design by&nbsp;<a href={authorUrl}><u>{author}</u></a>
                  </div>
              </div>
              <div className="col d-flex align-items-center justify-content-end">
                  <SocialLinks socialNetworks={socialNetworks}/>
              </div>
          </footer>
      </div>
  )
}

export default Footer;
