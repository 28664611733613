export const links = [
    {
        label: 'nav.menu.label',
        url: 'nav.menu.url',
        anchor: true
    },
    {
        label: 'nav.events.label',
        url: 'nav.events.url',
        anchor: true
    },
    {
        label: 'nav.setup.label',
        url: 'nav.setup.url',
        anchor: true
    },
    {
        label: 'nav.about-us.label',
        url: 'nav.about-us.url',
        anchor: true
    },
    {
        label: 'nav.contact.label',
        url: 'nav.contact.url',
        anchor: true
    }
];

export const baseURL = {
    en: '/en/',
    nl: '/'
};